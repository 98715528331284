body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container{
  display: grid;
  height: 100vh;
  grid-template-columns: auto 600px auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-width: 2048px) {
  .main-container{
    grid-template-columns: auto 600px auto;
  }
}

@media screen and (max-width: 1920px) {
  .main-container{
    grid-template-columns: auto auto;
  }
}


@media screen and (max-width: 1080px) {
  .main-container{
    grid-template-columns: auto;
  }
}


.javascript-container {
  height: 100%;
}

.result-container{
  height: 100%;
}


.source-container{
}

@media screen and (max-width: 1920px) {
  .source-container{
    grid-row-start: span 2;
  }
}